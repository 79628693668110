import axios, {AxiosInstance} from "axios"
import axiosRetry from "axios-retry";
import {AppStorage} from "../utils";
import {toast} from "react-toastify";
import routes from "../constants/routes";

const { REACT_APP_BACKEND_URL } = process.env

const axiosInstance: AxiosInstance = axios.create({
    baseURL: REACT_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json"
    },
    timeout: 60000,
})

axiosRetry(axiosInstance, {
    retries: 3,
    shouldResetTimeout: true,
});

axiosInstance.interceptors.request.use(
    config =>  config,
    error =>  Promise.reject('Erreur --> ' + error)
)

axiosInstance.interceptors.response.use(
    res => {
        if(typeof res.data !== "object") {
            //toast.warning("Oups! Erreur inattendue. Réessayez SVP.")
            return false
        }
        return res.data
    },
    err => {
        if(!err || !err.response) {
            toast.warning("Problème de connexion, réessayez SVP.")
            console.log("No response", err)
            return false
        }
        if(err.response.status === 419 || err.response.status === 403){
            toast.warning("Votre session a expiré, reconnectez-vous SVP.")
            AppStorage.clearAll()
            window.location.href = routes.login
            return false
        }
        if(err.response.status !== 200) {
            toast.error("Oups! Erreur inattendue. Réessayez SVP.")
            return {
                error: true,
                status: err.response.status,
                message: err.response.data.message || "Oups! Erreur inattendue. Réessayez SVP."
            }
        }
        return err.response
    }
)

const methods = {
    get: axiosInstance.get,
    post: axiosInstance.post,
    put: axiosInstance.put,
    patch: axiosInstance.patch,
    delete: axiosInstance.delete
}

export default methods
