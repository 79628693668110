import React from 'react';
import {Route, Routes} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Footer, HeaderBar} from './components';
import routes from './constants/routes';
import {Home, Invoice, PaymentForm} from "./screens";
import "react-toastify/dist/ReactToastify.css";
import './index.css';

function App() {
    return (
        <div className="transition duration-200 ease-in-out">


            <div className="flex">
                <div className="w-full h-screen pb-10">
                    <HeaderBar/>
                    <div className="">
                        <Routes>
                            <Route>
                                <Route path={routes.home} element={<Home/>}/>
                                <Route path={routes.payment} element={<PaymentForm/>}/>
                                <Route path={routes.invoice} element={<Invoice />}/>

                                <Route path="*" element={<Home/>}/>
                            </Route>
                        </Routes>
                    </div>
                    <Footer/>
                </div>
            </div>


            <ToastContainer/>
        </div>
    );
}

export default App;
