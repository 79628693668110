const logoShortWhite = require("./images/white-logo-united-1.png")
const frFlag = require("./images/fr.png")
const mpesaLogo = require("./images/mpesa-logo.webp")
const orangeMoneyLogo = require("./images/orange-money-logo.jpeg")
const airtelMoneyLogo = require("./images/airtelmoney-logo.png")

export const images = {
    logoShortWhite,
    frFlag,
    mpesaLogo,
    orangeMoneyLogo,
    airtelMoneyLogo
}
