import http from "./httpService";
import {auth} from "./index";
import http2 from "./http2Service";
import {toast} from "react-toastify";
import fileDownload from 'js-file-download';

const token = auth.getAuthToken()
const headers = { "Authorization": "Bearer " + token }

const { REACT_APP_BACKEND_URL } = process.env

const get = async (url: string, base: boolean = true) => {
    const res = base ? await http.get(url, {headers}) : await http2.get(url, {headers})
    return res
}


const put = async (url:string, data?:any) => {
    const {data: res} = await http.put(url, data, {headers})
    return res
}

const patch = async (url:string, data?:any) => {
    const {data: res} = await http.patch(url, data, {headers})
    return res
}

const fetchDownload = async (url: string,  filename: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Auth-Token", token || "");
    const res = await fetch(`${REACT_APP_BACKEND_URL}${url}`, {method: 'GET', headers: myHeaders})
    const blob = await res.blob()
    if(blob.type == "" || blob.type !== "application/pdf") {
        toast.error("Une erreur est survenue ! Ressayez SVP.")
        return
    }
    fileDownload(blob, filename);
}
const post = async (url:string, data?:any, base: boolean = true) => {
    const res:{[key: string]: any} = base ? await http.post(url, data, {headers}) :  await http2.post(url, data, {headers})
    return res
}

const exps = {
    get,
    put,
    patch,
    post,
    fetchDownload
}

export default exps
