import {Link} from "react-router-dom";
import {FaInstagram, FaLinkedinIn} from "react-icons/fa";
import {ImFacebook} from "react-icons/im";
import {images} from "../../assets/images";

const Footer = () => {
    return (
        <div className="text-center  w-full ">
            <div className="font-bold text-blue-700 text-lg py-4">
                <Link target="_blank" to="https://web.facebook.com/people/United-RDC/100070401306449/" className="px-3"><ImFacebook
                    className="inline"/></Link>
                <Link target="_blank" to="https://www.linkedin.com/company/united-sa/" className="px-3"><FaLinkedinIn
                    className="inline "/></Link>
                <Link target="_blank"  to="https://www.instagram.com/united_rdc/" className="px-3"><FaInstagram className="inline"/></Link>
            </div>
            <div style={{backgroundColor: '#0c4094'}} className="py-4">
                <span className="text-sm text-white">PROPULSÉ PAR UNITED  ©2022</span>
            </div>
            <div className="pt-4 pb-10">
                <img className="inline" src={images.frFlag} alt="fr flag"/>
                <span className="pl-2">Français</span>
            </div>
        </div>
    )
}

export default Footer
