import http from "./httpService"
import {AppStorage} from "../utils";
import endpoints from "../constants/endpoints";
import routes from "../constants/routes";
import {ResponseType} from "../types/common";
import {storageKeys} from "../constants/common";

const login: Function = async (posts: {}) => {
    const res: ResponseType = await http.post(endpoints.login, posts)
    if (res && res?.token) {
        AppStorage.setItem(storageKeys.token, res.token)
        return true
    }
    return false
}

const logout: Function = async () => {
    //await http.post(endpoints.logout)
    AppStorage.clearAll()
    window.location.href = routes.login
    return true
}


const getAuthToken: () => string | null = () => AppStorage.getItem(storageKeys.token)

const exps = {
    login,
    logout,
    getAuthToken,
}

export default exps
