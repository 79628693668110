import {useMutation, useQuery} from "react-query";
import {https} from "./index";
import endpoints from "../constants/endpoints";
import {cacheTime} from "../constants/common";
import {FormatUrlParams, GetQueryKey} from "../utils";

type PayType = {
    order : {
        paymentPageId: string
        customerFullName: string
        transactionReference: string
        customerEmailAddress: string
        currency: string
        amount: number
        redirectURL?: string
    },
    paymentChannel: {
        channel: string
        provider?: string
        walletid?: string
    }
};

type MailType = {
    name: string
    email: string
    phone: string
    address: string
    amount: string
    invoice: string
    phoneNumber: string
    transactionId: string
    transactionStatus: string
};

type QueryParams = {
    name: string
    email: string
    phone: string
    address: string
    amount: string
    invoice: string
    phoneNumber: string
    transactionId: string
    transactionStatus: string
};

export const UseLogin = () =>
    useMutation(async (body: any) => https.post(endpoints.login, body));


export const UseGetStatusInquiry = (transactionId: string) =>
    useQuery(["statusInquiry"], async () => https.get(`${endpoints.statusInquiry}/${transactionId}`), {staleTime: cacheTime.none, enabled: false});

export const UsePay = () =>
    useMutation(async (body: PayType) => https.post(endpoints.pay, body));

export const UseSendEmail = () =>
    useMutation(async (body: MailType) => https.post(endpoints.sendMail, body, false));
export const UseGetInvoice = (options?: QueryParams) =>
    useQuery(
        GetQueryKey("invoice", options),
        async () => await https.fetchDownload(`${endpoints.getInvoice}${FormatUrlParams(options)}`, `invoice.pdf`),
        {staleTime: cacheTime.long, enabled: false}
    );

