import React, {Fragment, useRef} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {AppButton, Spinner} from "../index";
import {BsX} from "react-icons/bs";

type Props = {
    content?: React.ReactNode
    isOpen?: boolean,
    isLoading?: boolean
    onValidate?: Function
    onCancel?: Function
    validateLabel?: string
    cancelLabel?: string
}
const BlockingModal: React.FC<Props> = (props: Props) => {
    const {
        isOpen = false,
        onValidate,
        isLoading,
        onCancel,
        content,
        validateLabel = "Oui, Valider",
        cancelLabel = "Fermer"
    } = props
    const cancelButtonRef = useRef(null)
    return (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => {
            }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="fixed inset-0 bg-gray-500 dark:bg-gray-700 dark:bg-opacity-40 bg-opacity-40 transition-opacity"/>
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div
                        className="flex relative items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                                <div className="absolute p-1 rounded-full top-1 right-1 bg-white border cursor-pointer"
                                     onClick={onCancel ? () => onCancel() : () => {
                                     }}><BsX className=""/></div>
                                <div className="bg-white px-4 pt-5 pb-4 sm:p-3 sm:pb-4">
                                    <div className="sm:flex flex-col items-center mt-8">
                                        {content}
                                    </div>
                                </div>
                                {/*<div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">*/}
                                {/*    {onValidate && <div className="w-1/3">*/}
                                {/*        <AppButton onClick={onValidate} bgColor="bg-red-500 hover:bg-red-700" size="sm"*/}
                                {/*                   label={!isLoading ? validateLabel : <Spinner/>}/>*/}
                                {/*    </div>}*/}
                                {/*    <div className="mr-3 w-1/3">*/}
                                {/*        <AppButton onClick={onCancel} bgColor="bg-gray-500 hover:bg-gray-700" size="sm"*/}
                                {/*                   label={cancelLabel}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default BlockingModal
