const urls =  {
    app: 'http://localhost:3000/',
    login: "/login",
    logout: "/logout",
    pay: "/pay/paymentrequest",
    statusInquiry: "/reporting/transactionstatus",
    sendMail: "/api/send-mail",
    getInvoice: "/api/get-invoice",
}

export default urls
