import {forwardRef, LegacyRef, useState} from "react";
import {BsEye, BsEyeSlash} from "react-icons/bs"
import {InputFormType} from "../../types/common";
import {RequiredMark} from "../index";

const AppInput: React.FC<InputFormType> = forwardRef((props: InputFormType, ref: LegacyRef<any>) => {
    const {
        label,
        name,
        type="text",
        containerClassName,
        required,
        placeholder,
        error,
        errorMessage,
        className,
        leftIcon,
        rightIcon,
        isTextarea,
        rows,
        disabled,
        ...rest} = props

    const [usedType, setUsedType] = useState(type)
    const togglePassword = () => {
        if(usedType === "password") setUsedType("text")
        else setUsedType("password")
    }
    return (
        <div className={`${containerClassName} mb-1`}>
            {label && <label htmlFor={name} className="">{label} {required && <RequiredMark />}</label>}
            <div className="relative">
                {!isTextarea ?
                    <>
                        {rightIcon  &&
                            <span className="absolute left-3 top-[6px] cursor-pointer"> {rightIcon} </span>
                        }
                        <input
                            ref={ref}
                            name={name}
                            type={usedType}
                            placeholder={placeholder}
                            className={`${className} ${rightIcon ? "pl-14 pr-2" : leftIcon ? "pr-14" : "px-2"} border-2 ${error ? 'border-red-300 focus:outline-none' : 'border-slate-400'} w-full  py-1 rounded-sm placeholder:text-gray-400 ${disabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white'}`}
                            required={required}
                            disabled={disabled}
                            {...rest}
                        />
                        {type === "password" &&
                            <span onClick={togglePassword} className="absolute right-3 top-2.5 cursor-pointer">
                                    {usedType === "password" ? <BsEye /> : <BsEyeSlash />}
                                </span>
                        }
                        {leftIcon  &&
                            <span className="absolute right-3 top-[6px] cursor-pointer"> {leftIcon} </span>
                        }
                    </>
                    :
                    <div>
                    <textarea
                        ref={ref}
                        {...rest}
                        name={name}
                        id={name}
                        rows={rows}
                        placeholder={placeholder}
                        className={`${error ? 'border-red-300 focus:outline-none' : 'border-slate-400'}  appearance-none  border-2  placeholder:text-sm placeholder:font-thin placeholder:text-gray-400  w-full rounded  px-3 py-2 shadow-sm  bg-white`}
                    />
                    </div>
                }
            </div>
            {error && errorMessage ? <div className="font-medium text-xs text-sm mt-1 text-red-500">{errorMessage}</div> : <div className="font-medium text-xs text-sm mt-1 text-red-500">&nbsp;</div>}

        </div>
    )
})

export default AppInput
