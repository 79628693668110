import {images} from "../assets/images";

export const storageKeys = {
    token: "_unitedToken",
    customer: "_unitedCustomer",
}
export const assetsUrl = process.env.REACT_APP_ASSETS_URL

export const currencies = [
    {
        id: 1,
        title: 'USD',
        short: '$',
    },
    {
        id: 2,
        title: 'CDF',
        short: 'FC',
    },
];

export const currenciesObj: {[key: string]: {title: string; short: string}} = {
    USD: {
        title: 'USD',
        short: '$',
    },
    CDF: {
        title: 'CDF',
        short: 'FC',
    },
};

export const momoBrands = {
    mpesa: {
        title: 'MPesa',
        company: 'VodaCom RDC',
        logo: images.mpesaLogo,
        prefix: /^(\81|\82|\83)/,
    },
    orange: {
        title: 'Orange Money',
        company: 'Orange RDC',
        logo: images.orangeMoneyLogo,
        prefix: /^(\85|\84|\80|\89)/,
    },
    airtel: {
        title: 'Airtel Money',
        company: 'Airtel RDC',
        logo: images.airtelMoneyLogo,
        prefix: /^(\99|\97)/,
    },
};

export const cacheTime = {
    long: 9990000,
    medium: 60000,
    short: 1000,
    none: 0
}


export const apiKeys = {
    paymentPageId: "58DA3AB9-B4BF-4AD2-B5A7-833325B59FA9",
    customerFullName: "John Doe",
    transactionReference: "C18EF6BD-4E50-493F-A06A-30868C450FB0",
    customerEmailAddress: "john@email.com",
}