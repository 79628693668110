import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {assetsUrl, storageKeys} from "../../constants/common";
import {AppButton, AppInput} from "../../components";
import regex from "../../constants/regex";
import {BsEnvelope, BsFillFileEarmarkPostFill, BsFillPinMapFill, BsPersonCircle, BsTelephone} from "react-icons/bs";
import routes from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import {auth} from "../../services";
import {toast} from "react-toastify";
import {AppStorage} from "../../utils";

const Home = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "all"});

    useEffect(() => {
        AppStorage.clearAll()
    }, [])

    const onSubmit = async (data: any) => {
        setIsLoading(true)
        const res = await auth.login({
            emailAddress: "pay@united.cd",
            password: "jBZPBXNn3aF6SRc"
        })
        setIsLoading(false)
        if(res) {
            //navigate(routes.payment, {state: {customer: data}})
            AppStorage.setItem(storageKeys.customer, JSON.stringify(data))
            window.location.href = routes.payment
        }
        else toast.warning("Oups! Erreur inattendue. Réessayez SVP.")
        return
    }

    return (
        <div className="w-full h-[700px]  grid lg:grid-cols-3 grid-cols-2 lg:px-40 px-5 bg-gray-50  py-10">
            <div
                style={{backgroundImage: `url("${assetsUrl}/online-pay.jpg")`, backgroundSize: "cover"}}
                className="w-full h-full lg:col-span-1 hidden lg:block bg-blue-800">
            </div>
            <div className="col-span-2 bg-white shadow-lg p-14">
                <div className="text-xl font-bold">Vos Coordonnées</div>

                <div className=" mt-4 ">
                    <form className="grid grid-cols-2 gap-x-4" onSubmit={handleSubmit(onSubmit)}>
                        <div className="col-span-2">
                            <AppInput
                                {...register("name")}
                                label="Nom complet"
                                name="name"
                                rightIcon={<BsPersonCircle className="inline"/>}
                                error={!!(errors && errors["name"])}
                                errorMessage={errors["name"]?.message?.toString()}
                            />
                        </div>
                        <div className="lg:col-span-1 col-span-2  mt-3">
                            <AppInput
                                {...register("phone")}
                                label="Téléphone"
                                name="phone"
                                rightIcon={<BsTelephone className="inline"/>}
                                error={!!(errors && errors["phone"])}
                                errorMessage={errors["phone"]?.message?.toString()}
                            />
                        </div>

                        <div className="lg:col-span-1 col-span-2  mt-3">
                            <AppInput
                                {...register("email", {
                                    required: "Entrez votre adresse email",
                                    pattern: {
                                        value: regex.email,
                                        message: `Entrez une adresse valide`,
                                    },
                                })}
                                rightIcon={<BsEnvelope className="inline"/>}
                                label="Email"
                                required
                                name="email"
                                error={!!(errors && errors["email"])}
                                errorMessage={errors["email"]?.message?.toString()}
                            />
                        </div>

                        <div className="col-span-2 mt-3">
                            <AppInput
                                {...register("address")}
                                label="Votre adresse"
                                name="address"
                                rightIcon={<BsFillPinMapFill className="inline"/>}
                                error={!!(errors && errors["address"])}
                                errorMessage={errors["address"]?.message?.toString()}
                            />
                        </div>


                        <div className="col-span-2 mt-3">
                            <AppInput
                                {...register("invoice", {
                                    required: "Entrez le numéro de votre facture",
                                })}
                                label="Numéro de la facture"
                                name="invoice"
                                required
                                rightIcon={<BsFillFileEarmarkPostFill className="inline"/>}
                                error={!!(errors && errors["invoice"])}
                                errorMessage={errors["invoice"]?.message?.toString()}
                            />
                        </div>

                        <div className="col-span-2">
                            <AppButton
                                type="submit"
                                label="PROCÉDER AU PAIEMENT"
                                className="bg-green-600 hover:bg-green-800 py-4 mt-8"
                                isLoading={isLoading}
                            />
                        </div>
                    </form>

                </div>

            </div>
        </div>
    )
}

export default Home
