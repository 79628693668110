import {Link} from "react-router-dom";
import {images} from "../../assets/images";

const HeaderBar = () => {
    return (
        <div style={{backgroundColor: '#0c4094'}} className="w-full text-center grid lg:grid-cols-3 grid-cols-1 items-center text-white py-4 px-10">
            <div className="hidden lg:block">
                <Link className="headerLink" to="https://unitedrdc.com">ACCUEIL</Link>
                <Link className="headerLink" to="https://unitedrdc.com/a-propos">A PROPOS</Link>
                <Link className="headerLink" to="https://unitedrdc.com/service-internet">NOS SERVICES</Link>
            </div>
            <div>
                <Link className="headerLink" to="https://unitedrdc.com"><img src={images.logoShortWhite} className="w-[35px] inline" alt=""/></Link>
            </div>
            <div className="hidden lg:block">
                <Link className="headerLink" to="https://unitedrdc.com/contact">CONTACT</Link>
                <Link className="headerLink" to="https://unitedrdc.com/portal/login">ESPACE CLIENT</Link>
            </div>
        </div>
    )
}

export default HeaderBar
