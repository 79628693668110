import {BsFillCheckCircleFill} from "react-icons/bs";

type PropsType = {
    img: string,
    isSelected: boolean
    className?: string
    onCLick: () => void
}

const MomoBrand = (props: PropsType) => {
    const {img, isSelected = true, className, onCLick} = props
    return (
        <div onClick={onCLick} className="inline mx-1 lg:mx-3 relative cursor-pointer">
            {isSelected && <BsFillCheckCircleFill className="inline-block absolute bg-white rounded-full -mt-2 -ml-2 text-green-700 text-3xl"/>}
            <img className={`inline lg:w-[70px] w-[60px]  rounded ${className}`} src={img} alt="logo"/>
        </div>
    )
}

export default MomoBrand
