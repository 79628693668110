import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {AppButton, AppInput, BlockingModal, MomoBrand, Spinner} from "../../components";
import {images} from "../../assets/images";
import {apiKeys, momoBrands, storageKeys} from "../../constants/common";
import regex from "../../constants/regex";
import routes from "../../constants/routes";
import {UseGetStatusInquiry, UsePay, UseSendEmail} from "../../services";
import appStorage from "../../utils/AppStorage";
import {AppStorage} from "../../utils";

const MomoPayment = () => {
    const navigate = useNavigate()
    const customerStr = appStorage.getItem(storageKeys.customer)
    const customer = JSON.parse(customerStr ? customerStr : "")
    if (!customer) {
        window.location.href = routes.home
    }
    const [selectedMomo, setSelectedMomo] = useState<any>(momoBrands.mpesa)
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [transactionId, setTransactionId] = useState<string>("");
    const [isPending, setIsPending] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({});

    const date = new Date()

    const {
        register,
        handleSubmit,
        setValue,
        clearErrors,
        formState: {errors},
    } = useForm({mode: "all"});

    const {data: transactionData, isLoading: isGettingStatus, refetch: getStatus} = UseGetStatusInquiry(transactionId)
    const {data: payData, isLoading: isPaying, mutate: pay} = UsePay()
    const {mutate: sendEmail} = UseSendEmail()

    const onClickMomo = (brand: any) => {
        setSelectedMomo(brand)
        setValue("phoneNumber", "")
        clearErrors("phoneNumber")
    }

    // const getStatus = async (id: string) => {
    //     const res = await https.get(`${endpoints.statusInquiry}/${id}`)
    //     setTransactionData(res)
    // }

    useEffect(() => {
        if (transactionData && transactionData?.status.toString() === "PENDING") {
            onCloseModal()
            return
        }
        if (transactionData && transactionData?.status.toString() === "APPROVED") {
            navigate(`${routes.invoice}?transactionStatus=SUCCESS`)
            return
        }
        if (transactionData && transactionData?.status.toString() === "DECLINED") {
            navigate(`${routes.invoice}?transactionStatus=DECLINED`)
        }
    }, [transactionData, isGettingStatus])


    useEffect(() => {
        if (payData && payData.transactionId) {
            setTransactionId(payData.transactionId)
            setIsModalOpen(true)
            setTimeout(async () => {
                await getStatus()
                AppStorage.setItem(storageKeys.customer, JSON.stringify({
                    ...formData,
                    ...customer,
                    paymentType: "MOBILEMONEY",
                    transactionId: payData.transactionId
                }))
            }, 30000)

        }
    }, [payData])

    const onCloseModal = () => {
        setIsPending(true)
    }

    const onConfirm = async () => {
        await getStatus()
    }

    const onSubmit = (data: any) => {
        pay({
            order: {
                paymentPageId: apiKeys.paymentPageId,
                customerFullName: customer.name,
                transactionReference: date.toTimeString(),
                customerEmailAddress: customer.email,
                currency: "USD",
                amount: parseFloat(data.amount),
            },
            paymentChannel: {
                channel: "MOBILEMONEY",
                provider: selectedMomo.title,
                walletid: `+243${data.phoneNumber}`
            }
        })
        setFormData({phoneNumber: `+243${data.phoneNumber}`, amount: parseFloat(data.amount)})
        //console.log(data, customer)
    }

    return (
        <div>
            <BlockingModal
                isOpen={isModalOpen}
                onCancel={onCloseModal}
                content={
                    <>
                        {isPending ?
                            (<div>
                                <p className="text-xl font-bold text-center mt-5 mb-2">Avez-vous déjà confirmé ?</p>
                                <p className="text-gray-600 text-sm mb-4 text-center">Si vous avez confirmé le paiement
                                    sur votre téléphone, Cliquez pour valider SVP.</p>
                                <div className="flex flex-col items-center px-20 mt-6 mb-8">
                                    <AppButton
                                        isButton={false}
                                        label="Oui, j'ai confirmé."
                                        className="bg-green-600 hover:bg-green-800"
                                        isLoading={isGettingStatus}
                                        onClick={onConfirm}
                                    />
                                    <AppButton
                                        isButton={false}
                                        label="Non, annuler."
                                        size="sm"
                                        className="bg-red-400 hover:bg-red-600 mt-3 w-1/2"
                                        onClick={() => {
                                            setIsModalOpen(false)
                                            navigate(routes.home)
                                        }}
                                    />
                                </div>
                            </div>)
                            : (<div className="mb-5">
                                <Spinner label=" "/>
                                <p className="text-xl font-bold text-center mt-5 mb-2">Confirmation</p>
                                <p className="text-gray-600 text-sm mb-4 text-center">Veuillez confirmer le paiement sur
                                    votre téléphone
                                    SVP.</p>
                            </div>)}
                    </>
                }

            />


            <div className="mb-8 text-center">
                <MomoBrand
                    className="border border-red-600"
                    img={images.mpesaLogo}
                    isSelected={selectedMomo === momoBrands.mpesa}
                    onCLick={() => onClickMomo(momoBrands.mpesa)}
                />
                <MomoBrand
                    img={images.orangeMoneyLogo}
                    isSelected={selectedMomo === momoBrands.orange}
                    onCLick={() => onClickMomo(momoBrands.orange)}
                />
                <MomoBrand
                    img={images.airtelMoneyLogo}
                    isSelected={selectedMomo === momoBrands.airtel}
                    onCLick={() => onClickMomo(momoBrands.airtel)}
                />
            </div>
            <form className="grid grid-cols-2 gap-x-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="col-span-2">
                    <AppInput
                        {...register("phoneNumber", {
                            required: "Entrez votre numéro de téléphone",
                            pattern: {
                                value: selectedMomo.prefix,
                                message: `Numéro ${selectedMomo.title} incorrect`,
                            },
                            minLength: {
                                value: 9,
                                message: "Entrez un numéro valide"
                            },
                            maxLength: {
                                value: 9,
                                message: "Entrez un numéro valide"
                            },
                        })}
                        label="Numéro de Téléphone"
                        name="phoneNumber"
                        error={!!(errors && errors["phoneNumber"])}
                        errorMessage={errors["phoneNumber"]?.message?.toString()}
                        rightIcon={<span>+243</span>}
                    />
                </div>

                <div className="col-span-2">
                    <AppInput
                        {...register("amount", {
                            required: "Entrez le montant à payer",
                            pattern: {
                                value: regex.numbers,
                                message: `Entrez un montant valide`,
                            },
                        })}
                        label="Montant à payer"
                        name="amount"
                        error={!!(errors && errors["amount"])}
                        errorMessage={errors["amount"]?.message?.toString()}
                        rightIcon={<span className="text-xs font-bold">USD</span>}
                    />
                </div>

                <div className="col-span-2">
                    <AppButton
                        type="submit"
                        label="VALIDER LE PAIEMENT"
                        className="bg-green-600 hover:bg-green-800 py-4 mt-8"
                        isLoading={isPaying}
                    />
                </div>
            </form>
        </div>
    )
}

export default MomoPayment
