import {useForm} from "react-hook-form";
import {useLocation} from "react-router-dom";
import {AppButton, AppInput, BlockingModal} from "../../components";
import regex from "../../constants/regex";
import {UsePay} from "../../services";
import routes from "../../constants/routes";
import {useEffect, useState} from "react";
import appStorage from "../../utils/AppStorage";
import {apiKeys, storageKeys} from "../../constants/common";
import {AppStorage} from "../../utils";

const CardPayment = () => {
    const {state} = useLocation();
    const customerStr = appStorage.getItem(storageKeys.customer)
    const customer = JSON.parse(customerStr ? customerStr : "")
    if (!customer) window.location.href = routes.home
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({mode: "all"});
    const [pageURL, setPageURL] = useState<string>("");
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>({});

    const date = new Date()

    const {data: payData, isLoading: isPaying, mutate: pay} = UsePay()

    useEffect(() => {
        if (payData && payData.paymentPage) {
            setPageURL(payData.paymentPage)
            AppStorage.setItem(storageKeys.customer, JSON.stringify({
                ...formData,
                ...customer,
                paymentType: "CARD",
                transactionId: payData.transactionId
            }))
            window.open(payData.paymentPage, '_blank');
        }
    }, [payData])

    const onSubmit = (data: any) => {
        pay({
            order: {
                paymentPageId: apiKeys.paymentPageId,
                customerFullName: customer.name,
                transactionReference: date.toTimeString(),
                customerEmailAddress: customer.email,
                currency: "USD",
                amount: parseFloat(data.amount),
                redirectURL: "https://pay.unitedrdc.com/invoice"
            },
            paymentChannel: {
                channel: "CARD",
            }
        })
        setFormData({phoneNumber: `-`, amount: parseFloat(data.amount)})
    }
    return (
        <div>

            <BlockingModal
                isOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                content={
                    <div className="mb-5">
                        <iframe src={pageURL} height={400} width={300} title="description"></iframe>
                    </div>
                }
            />

            <form className="grid grid-cols-2 gap-x-4" onSubmit={handleSubmit(onSubmit)}>
                {/*<div className="col-span-2">*/}
                {/*    <AppInput*/}
                {/*        {...register("cardNumber", {*/}
                {/*            required: "Entrez le numéro de la carte",*/}
                {/*        })}*/}
                {/*        label="Numéro de la carte"*/}
                {/*        placeholder="XXXX XXXX XXXX XXXX"*/}
                {/*        name="cardNumber"*/}
                {/*        error={!!(errors && errors["cardNumber"])}*/}
                {/*        errorMessage={errors["cardNumber"]?.message?.toString()}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-span-1  mt-3">*/}
                {/*    <AppInput*/}
                {/*        {...register("expiry", {*/}
                {/*            required: "Entrez la date d'expiration",*/}
                {/*        })}*/}
                {/*        label="Date d'expiration"*/}
                {/*        placeholder="MM/YY"*/}
                {/*        name="expiry"*/}
                {/*        error={!!(errors && errors["expiry"])}*/}
                {/*        errorMessage={errors["expiry"]?.message?.toString()}*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<div className="col-span-1  mt-3">*/}
                {/*    <AppInput*/}
                {/*        {...register("cvv", {*/}
                {/*            required: "Entrez le code CVV",*/}
                {/*        })}*/}
                {/*        label="CVV"*/}
                {/*        name="cvv"*/}
                {/*        error={!!(errors && errors["cvv"])}*/}
                {/*        errorMessage={errors["cvv"]?.message?.toString()}*/}
                {/*    />*/}
                {/*</div>*/}


                <div className="col-span-2 mt-3">
                    <AppInput
                        {...register("amount", {
                            required: "Entrez le montant à payer",
                            pattern: {
                                value: regex.numbers,
                                message: `Entrez un montant valide`,
                            },
                        })}
                        label="Montant à payer"
                        name="amount"
                        error={!!(errors && errors["amount"])}
                        errorMessage={errors["amount"]?.message?.toString()}
                        rightIcon={<span className="text-xs font-bold">USD</span>}
                    />
                </div>

                <div className="col-span-2">
                    <AppButton
                        type="submit"
                        label="CONTINUER"
                        className="bg-green-600 hover:bg-green-800 py-4 mt-8"
                        isLoading={isPaying}
                    />
                </div>
            </form>

        </div>
    )
}

export default CardPayment
