import {AppButton} from "../../components";
import {BsCheck2Circle} from "react-icons/bs";
import {BiMessageSquareError} from "react-icons/bi";
import {useEffect} from "react";
import {UseSendEmail, UseGetInvoice} from "../../services";
import appStorage from "../../utils/AppStorage";
import {storageKeys} from "../../constants/common";

const Invoice = () => {
    const params = new URLSearchParams(window.location.search)
    const status = params.get("transactionStatus")
    const customerStr = appStorage.getItem(storageKeys.customer)
    const customer = JSON.parse(customerStr ? customerStr : "")
    const {mutate: sendEmail} = UseSendEmail()
    const {refetch: getInvoice, isLoading: isGetting} = UseGetInvoice({
        ...customer,
        transactionStatus: status
    })

    useEffect(() => {
        setTimeout(async () => {
            sendEmail({
                ...customer,
                transactionStatus: status
            })
        }, 1000)
    }, [])

    const onDownload = async () => {
        await getInvoice()
    }

    return (
        <div className="flex justify-center  lg:px-40 px-5 bg-gray-50 pt-20 pb-40">

            {status === "SUCCESS" ? <div className="bg-white shadow-lg p-14 lg:w-1/2 w-full text-center">
                <BsCheck2Circle className="text-green-500 inline text-4xl"/>
                <p className="text-xl font-bold text-center mt-5 mb-2">Votre paiement a été effectué avec succès.</p>
                <p className="text-gray-600 text-sm mb-4 text-center">Cliquer pour télécharger la facture</p>
                <AppButton
                    isButton={false}
                    label="Télécharger la facture"
                    className="bg-green-600 hover:bg-green-800"
                    isLoading={isGetting}
                    onClick={onDownload}
                />
            </div> : <div className="bg-white shadow-lg p-14 lg:w-1/2 w-full text-center">
                <BiMessageSquareError className="text-red-500 inline text-4xl"/>
                <p className="text-xl font-bold text-danger text-center mt-5 mb-2">Le paiement n'a pas abouti.</p>
                <p className="text-gray-600 text-sm mb-4 text-center">Veuillez ressayer SVP.</p>
            </div>}
        </div>
    )
}

export default Invoice
