import {useState} from "react";
import {assetsUrl} from "../../constants/common";
import {CardPayment, MomoPayment} from "../index";

const PaymentForm = () => {
    const [selectedMethod, setSelectedMethod] = useState("momo")

    return (
        <div className="w-full h-[700px]  grid lg:grid-cols-3 grid-cols-2 lg:px-40 px-5 bg-gray-50  py-10">
            <div
                style={{backgroundImage: `url("${assetsUrl}/online-pay.jpg")`, backgroundSize: "cover"}}
                className="w-full h-full lg:col-span-1 hidden lg:block bg-blue-800">
            </div>
            <div className="col-span-2 bg-white shadow-lg p-14">
                <div className="text-xl font-bold">Méthodes de Paiement</div>

                <div className=" mt-4 ">
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 text-center">
                        <div
                            onClick={() => setSelectedMethod("momo")}
                            className={`${selectedMethod === "momo" ? 'border-blue-600 text-white bg-blue-600' : '  border-gray-500'} border py-4 cursor-pointer transition-all duration-500 ease-in-out`}
                        >
                            Monnaie Mobile
                        </div>
                        <div
                            onClick={() => setSelectedMethod("card")}
                            className={`${selectedMethod === "card" ? 'border-blue-600 text-white bg-blue-600' : '  border-gray-500'} border py-4 cursor-pointer transition-all duration-500 ease-in-out`}
                        >
                            Cartes
                        </div>
                    </div>
                    <div className="mt-8">
                        {
                            selectedMethod === "momo" ? <MomoPayment/> : <CardPayment/>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PaymentForm
